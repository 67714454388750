<template>
  <div class="container">
    <div class="dotAA" v-if="data.title === '认证审核' && numDatas.wait_audit">{{ numDatas.wait_audit }}</div>
    <div class="dotAA" v-else-if="data.title === '补充材料审核' && numDatas.supplement_audit">{{ numDatas.supplement_audit }}</div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useTimedTask } from '@/store';

const timedTask = useTimedTask();

const props = defineProps({
  data: Object,
});

const NUM_DATA = {
  wait_audit: 0,
  supplement_audit: 0,
};

const numDatas = reactive(NUM_DATA);
timedTask.$subscribe((mutation, state) => {
  numDatas.wait_audit = state.botNum?.wait_audit;
  numDatas.supplement_audit = state.botNum?.supplement_audit;
});
</script>

<style scoped>
.dotAA {
  width: 28px;
  height: 15px;
  background-color: red;
  border-radius: 20px;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
}
</style>
