import { request } from '@/utils/request';
import {
  LoginModel,
  LoginParams,
  RegisterParams,
  SendSmsModel,
  SendSmsParams,
  UserInfoModel, UserPasswordModel,
} from '@/api/model/LoginModel';
import { Result } from '@/types/axios';

const Api = {
  Login: '/login',
  register: '/register',
  sendSms: '/sendsms',
  userInfo: '/userinfo',
  userPassword: '/user/password',
};

export function loginApi(param: LoginParams) {
  return request.post<Result<LoginModel>>({
    url: Api.Login,
    data: param,
  });
}

export function registerApi(param: RegisterParams) {
  return request.post<Result>({
    url: Api.register,
    data: param,
  });
}

export function sendSmsApi(param: SendSmsParams) {
  return request.post<Result<SendSmsModel>>({
    url: Api.sendSms,
    data: param,
  });
}

export function userInfoApi() {
  return request.get<Result<UserInfoModel>>({
    url: Api.userInfo,
  });
}

export function userPasswordApi(param: UserPasswordModel) {
  return request.post<Result>({
    url: Api.userPassword,
    data: param,
  });
}
