import { defineComponent, PropType, computed, h } from 'vue';
import { prefix } from '@/config/global';
import { MenuRoute } from '@/types/interface';
import { getActive } from '@/router';
import DotNum from './DotNum.vue';

const getMenuList = (list: MenuRoute[], basePath?: string): MenuRoute[] => {
  if (!list) {
    return [];
  }
  // 如果meta中有orderNo则按照从小到大排序
  list.sort((a, b) => {
    return (a.meta?.orderNo || 0) - (b.meta?.orderNo || 0);
  });
  return list
    .map((item) => {
      const path = basePath ? `${basePath}/${item.path}` : item.path;
      return {
        path,
        title: item.meta?.title,
        name: item.name,
        icon: item.meta?.icon || '',
        children: getMenuList(item.children, path),
        meta: item.meta,
        redirect: item.redirect,
      };
    })
    .filter((item) => item.meta && item.meta.hidden !== true);
};

const renderIcon = (item: any) => {
  if (typeof item.icon === 'string') {
    return () => item.icon && <t-icon name={item.icon}></t-icon>;
  }
  if (item.icon && typeof item.icon.render === 'function') {
    return () =>
      h(item.icon.render(), {
        class: 't-icon',
      });
  }
  return () => '';
};

const getPath = (active: string, item: MenuRoute) => {
  if (active.startsWith(item.path)) {
    return active;
  }
  return item.meta?.single ? item.redirect : item.path;
};

// eslint-disable-next-line consistent-return
const renderDotNum = (item: any) => {
  try {
    if (item.dotNum && item.dotNum === true) {
      return <dot-num className="dot-num" data={item} />;
    }
    return '';
  } catch (e) {}
};

const useRenderNav = (active: string, list: Array<MenuRoute>) => {
  return list.map((item) => {
    if (!item.children || !item.children.length || item.meta?.single) {
      const href = item.path.match(/(http|https):\/\/([\w.]+\/?)\S*/);
      if (href) {
        return (
          <t-menu-item href={href?.[0]} name={item.path} value={getPath(active, item)} icon={renderIcon(item)}>
            {item.title}
          </t-menu-item>
        );
      }
      return (
        <t-menu-item
          name={item.path}
          value={getPath(active, item)}
          to={
            item.meta?.params
              ? { name: item.name, params: item.meta?.params }
              : { path: item.path, query: item.meta?.query }
          }
          icon={renderIcon(item)}
        >
          {item.title}
          {renderDotNum(item.children[0]?.meta)}
        </t-menu-item>
      );
    }
    return (
      <t-submenu name={item.path} value={item.path} title={item.title} icon={renderIcon(item)}>
        {item.children && useRenderNav(active, item.children)}
      </t-submenu>
    );
  });
};

export default defineComponent({
  components: {
    DotNum,
  },
  props: {
    navData: {
      type: Array as PropType<MenuRoute[]>,
      default: () => [],
    },
  },
  setup(props) {
    const active = computed(() => getActive());
    const list = computed(() => {
      const { navData } = props;
      return getMenuList(navData);
    });

    return {
      prefix,
      active,
      list,
      useRenderNav,
    };
  },
  render() {
    return <div>{this.useRenderNav(this.active, this.list)}</div>;
  },
});
