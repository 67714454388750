import { defineStore } from 'pinia';
import { store } from '@/store';

interface HomeData {
  wait_audit: number;
  supplement_audit: number;
  today_member: number;
  total_member: number;
}

const state = {
  botNum: {
    wait_audit: 0,
    supplement_audit: 0,
    today_member: 0,
    total_member: 0,
  },
};

export const useTimedTask = defineStore('timedTask', {
  state: () => state,
  getters: {
    botNums: (state) => state.botNum,
  },
  actions: {},
});

export function getTimedTask() {
  return useTimedTask(store);
}
