import Layout from '@/layouts';

export default [
  {
    path: '/webPageManagement',
    component: Layout,
    redirect: '/webPageManagement/webpagePrice',
    name: 'WebPageManagement',
    meta: { title: '报价管理', icon: 'mobile', single: true, roleCode: '' },
    children: [
      {
        path: 'webpagePrice',
        name: 'WebpagePrice',
        component: () => import('@/pages/WebPageManagement/index.vue'),
        meta: { title: '网页报价管理', roleCode: 'WebpagePrice' },
      },
    ],
  },
];
