<template>
  <suspense>
    <router-view :class="[mode]" :key="key" />
  </suspense>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import config from '@/config/style';
import { useSettingStore } from '@/store';

// 路由中添加key解决切换路由时页面不展示内容，刷新后才展示内容问题
const route = useRoute();
const store = useSettingStore();

const mode = computed(() => {
  return store.displayMode;
});

const key = computed(() => {
  return route.path + Math.random();
});

onMounted(async () => {
  store.updateConfig({ ...config });
});
</script>
<style lang="less" scoped>
@import '@/style/variables.less';

#nprogress .bar {
  background: var(--td-brand-color) !important;
}
</style>
