// v-viewer.js 图片预览配置
// https://mirari.cc/2021/05/31/Vue3%E5%9B%BE%E7%89%87%E6%B5%8F%E8%A7%88%E7%BB%84%E4%BB%B6v-viewer%EF%BC%8C%E6%94%AF%E6%8C%81%E6%97%8B%E8%BD%AC%E3%80%81%E7%BC%A9%E6%94%BE%E3%80%81%E7%BF%BB%E8%BD%AC%E7%AD%89%E6%93%8D%E4%BD%9C/
export const IMAGE_PREVIEW_OPTIONS = {
  zIndex: 3000,
  inline: false, // 默认值:false。支持内联模式。
  button: true, // 将按钮显示在查看器的右上角。
  navbar: true, // 指定导航栏的可见性。
  title: true, // 指定标题的可见性和内容。
  toolbar: true, // 指定工具栏及其按钮的可见性和布局。
  tooltip: true, // 当放大或缩小时，显示工具提示与图像比例(百分比)。
  movable: true, // 启用移动图像。
  zoomable: true, // 启用缩放图像。
  rotatable: false, // 启用旋转图像。
  scalable: true, // 启用旋转图像。启用缩放图像。
  transition: true, // 启用一些特殊元素的CSS3过渡。
  fullscreen: false, // 播放时启用请求全屏。
  keyboard: true, // 启用键盘支持。
  url: 'src', // 默认值:“src”。定义从何处获取原始图像URL以供查看。
};

// 合同状态枚举
export const CONTRACT_STATUS = {
  FAIL: 0,
  AUDIT_PENDING: 1,
  EXEC_PENDING: 2,
  EXECUTING: 3,
  FINISH: 4,
};

export const CONTRACT_STATUS_OPTIONS = [
  { value: CONTRACT_STATUS.FAIL, label: '审核失败' },
  { value: CONTRACT_STATUS.AUDIT_PENDING, label: '待审核' },
  { value: CONTRACT_STATUS.EXEC_PENDING, label: '待履行' },
  { value: CONTRACT_STATUS.EXECUTING, label: '审核成功' },
  { value: CONTRACT_STATUS.FINISH, label: '已完成' },
];

// 合同类型枚举
export const CONTRACT_TYPES = {
  MAIN: 0,
  SUB: 1,
  SUPPLEMENT: 2,
};

export const CONTRACT_TYPE_OPTIONS = [
  { value: CONTRACT_TYPES.MAIN, label: '主合同' },
  { value: CONTRACT_TYPES.SUB, label: '子合同' },
  { value: CONTRACT_TYPES.SUPPLEMENT, label: '补充合同' },
];

// 合同收付类型枚举
export const CONTRACT_PAYMENT_TYPES = {
  PAYMENT: 0,
  RECEIPT: 1,
};

// 通知的优先级对应的TAG类型
export const NOTIFICATION_TYPES = {
  low: 'primary',
  middle: 'warning',
  high: 'danger',
};

export const CERTIFICATE_AUDIT_TYPE = {
  FIRST: 1,
  AMEND: 2,
};

export const CERTIFICATE_AUDIT_OPTIONS = [
  { value: CERTIFICATE_AUDIT_TYPE.FIRST, label: '首次申请' },
  { value: CERTIFICATE_AUDIT_TYPE.AMEND, label: '修改信息' },
];

export const AUDIT_STATUS_TYPE = {
  AUDITED: 0,
  PASSED: 1,
  REJECT: 2,
};

export const AUDIT_STATUS_OPTIONS = [
  { value: AUDIT_STATUS_TYPE.AUDITED, label: '待审核' },
  { value: AUDIT_STATUS_TYPE.REJECT, label: '已驳回' },
  { value: AUDIT_STATUS_TYPE.PASSED, label: '已通过' },
];

export const SUPPLEMENT_TYPE = {
  KINSFOLK: 1,
  DRIVING_LICENSE: 2,
  VEHICLE_LICENSE: 3,
  HOUSE_PROPERTY_CARD: 4,
  BUSINESS_LICENSE: 5,
};

export const SUPPLEMENT_OPTIONS = [
  { value: SUPPLEMENT_TYPE.KINSFOLK, label: '亲属信息' },
  { value: SUPPLEMENT_TYPE.DRIVING_LICENSE, label: '驾驶证' },
  { value: SUPPLEMENT_TYPE.VEHICLE_LICENSE, label: '行驶证' },
  { value: SUPPLEMENT_TYPE.HOUSE_PROPERTY_CARD, label: '房产证' },
  { value: SUPPLEMENT_TYPE.BUSINESS_LICENSE, label: '营业执照' },
];
