import { defineStore } from 'pinia';
import { store } from '@/store';
import { loginApi, userInfoApi } from '@/api/login';
import { LoginParams, UserInfoModel } from '@/api/model/LoginModel';
import { CookiesUtils } from '@/utils/cookies';
import { RolesTypeMapImpl, ROLES_ROUTER } from '@/config/roles';

const InitUserInfo = {
  roles: [],
};

export const useUserStore = defineStore('user', {
  state: () => ({
    token: CookiesUtils.get() || 'main_token', // 默认token不走权限
    userInfo: InitUserInfo,
  }),
  getters: {
    roles: (state) => state.userInfo?.roles,
  },
  actions: {
    async loginActions(userInfo: LoginParams) {
      const { data } = await loginApi(userInfo);
      this.token = data.token;
      const cookieToken = CookiesUtils.getCooliesUtilsInstance();
      cookieToken.set({ value: data.token });
    },
    async getUserInfo() {
      const { data } = await userInfoApi();
      const userinfo: UserInfoModel = { ...data };
      userinfo.roles = [];
      const roleName = RolesTypeMapImpl.getRolesTypeMapImpl().getRoleName(data.type);
      userinfo.roles = ROLES_ROUTER[roleName];
      // @ts-ignore
      this.userInfo = userinfo;
    },
    async logout() {
      const cookieToken = CookiesUtils.getCooliesUtilsInstance();
      cookieToken.remove();
      this.token = '';
      this.userInfo = InitUserInfo;
    },
    async removeToken() {
      this.token = '';
    },
  },
});

export function getUserStore() {
  return useUserStore(store);
}
