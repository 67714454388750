import Layout from '@/layouts';

export default [
  {
    path: '/systemManagement',
    component: Layout,
    redirect: '/systemManagement/banner',
    name: 'SystemManagement',
    meta: { title: '系统管理', icon: 'laptop', single: false, orderNo: 99 },
    children: [
      {
        path: 'banner',
        name: 'Banner',
        component: () => import('@/pages/system/banner/index.vue'),
        meta: { title: 'Banner管理', roleCode: 'Banner' },
      },
      {
        path: 'advertise',
        name: 'Advertise',
        component: () => import('@/pages/system/advertise/index.vue'),
        meta: { title: '广告位管理', roleCode: 'Advertise' },
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/pages/system/notice/index.vue'),
        meta: { title: '通知栏管理', roleCode: 'Notice' },
      },
      {
        path: 'userList',
        name: 'UserList',
        component: () => import('@/pages/system/userList/index.vue'),
        meta: { title: '用户列表', roleCode: 'UserList' },
      },
      {
        path: 'baseConfig',
        name: 'BaseConfig',
        component: () => import('@/pages/system/base/index.vue'),
        meta: { title: '基本配置', roleCode: 'BaseConfig' },
      },
    ],
  },
];
