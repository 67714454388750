import Layout from '@/layouts';
import DashboardIcon from '@/assets/assets-slide-dashboard.svg';
import LogoutIcon from '@/assets/assets-slide-logout.svg';

export default [
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/base',
    name: 'Dashboard',
    meta: { title: '首页', icon: DashboardIcon, single: true },
    children: [
      {
        path: 'base',
        name: 'DashboardBase',
        component: () => import('@/pages/dashboard/base/index.vue'),
        meta: { title: '概览仪表盘', roleCode: 'DashboardBase' },
      },
    ],
  },
  {
    path: '/loginRedirect',
    name: 'LoginRedirect',
    meta: { title: '登录页', icon: LogoutIcon, hidden: true },
    component: () => import('@/layouts/blank.vue'),
    children: [
      {
        path: 'index',
        redirect: '/login',
        component: () => import('@/layouts/blank.vue'),
        meta: { title: '登录中心', roleCode: 'Base' },
      },
    ],
  },
  {
    path: '/loginInfo',
    name: 'LoginInfo',
    component: Layout,
    meta: { title: '个人中心', icon: 'user-circle', hidden: true },
    children: [
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: () => import('@/pages/loginInfo/index.vue'),
        meta: { title: '个人中心', roleCode: 'Base' },
      },
    ],
  },
];
