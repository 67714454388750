export type RolesTypeMap = Map<number, string>;

export type MenuName = string[];
export type RolesRouter = Record<string, MenuName>;

export const ROLES_ROUTER: RolesRouter = {
  Default: ['Base'],
  Appraisal: [
    'Base',
    'DashboardBase',
    'PriceManagementPhonePrice',
    'PriceManagementIPadPrice',
    'PriceManagementComputerPrice',
    'Banner',
    'Advertise',
    'Notice',
    'UserList',
    'BaseConfig',
  ],
  Protection: [
    'Base',
    'DashboardBase',
    'ProtectionManagementPhoneModel',
    'ProtectionManagementDigitalModel',
    'ProtectionManagementDifficultModel',
    'Banner',
    'Notice',
    'UserList',
  ],
  Webpage: ['Base', 'DashboardBase', 'Banner', 'Notice', 'UserList', 'BaseConfig', 'WebpagePrice'],
};

export class RolesTypeMapImpl {
  private static instance: RolesTypeMapImpl;

  private ROLES_TYPE_MAP: RolesTypeMap = new Map<number, string>();

  constructor() {
    this.ROLES_TYPE_MAP.set(1, 'Appraisal');
    this.ROLES_TYPE_MAP.set(2, 'Protection');
    this.ROLES_TYPE_MAP.set(3, 'Webpage');
  }

  public static getRolesTypeMapImpl(): RolesTypeMapImpl {
    if (!RolesTypeMapImpl.instance) {
      RolesTypeMapImpl.instance = new RolesTypeMapImpl();
    }
    return RolesTypeMapImpl.instance;
  }

  public getRoleName(key: number): string {
    if (key) {
      return <string>this.ROLES_TYPE_MAP.get(key);
    }
    return 'Default';
  }
}
