import Layout from '@/layouts';

export default [
  {
    path: '/priceManagement',
    component: Layout,
    redirect: '/priceManagement/phonePrice',
    name: 'PriceManagement',
    meta: { title: '报价管理', icon: 'mobile', single: false, roleCode: '' },
    children: [
      {
        path: 'phonePrice',
        name: 'PriceManagementPhonePrice',
        component: () => import('@/pages/priceManagement/index.vue'),
        meta: { title: '手机报价', roleCode: 'PriceManagementPhonePrice', params: { type: 1 } },
      },
      {
        path: 'iPadPrice',
        name: 'PriceManagementIPadPrice',
        component: () => import('@/pages/priceManagement/index.vue'),
        meta: { title: '平板报价', roleCode: 'PriceManagementIPadPrice', params: { type: 2 } },
      },
      {
        path: 'computerPrice',
        name: 'PriceManagementComputerPrice',
        component: () => import('@/pages/priceManagement/index.vue'),
        meta: { title: '电脑报价', roleCode: 'PriceManagementComputerPrice', params: { type: 3 } },
      },
    ],
  },
];
