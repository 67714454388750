import { MessagePlugin } from 'tdesign-vue-next';
// @ts-ignore
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

import { getPermissionStore, getUserStore } from '@/store';
import router from '@/router';

const permissionStore = getPermissionStore();
const userStore = getUserStore();

NProgress.configure({ showSpinner: false });

const { whiteListRouters } = permissionStore;

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const { token } = userStore;
  if (token) {
    if (to.path === '/login') {
      await userStore.logout();
      permissionStore.restore();
      next();
      return;
    }
    const { roles } = userStore;
    if (roles && roles.length > 0) {
      next();
    } else {
      try {
        await userStore.getUserInfo();
        const { roles } = userStore;
        await permissionStore.initRoutes(roles);
        if (router.hasRoute(String(to.name))) {
          next();
        } else {
          next(`/`);
        }
      } catch (error: any) {
        MessagePlugin.error(error);
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
  } else {
    /* white list router */
    if (whiteListRouters.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done();
});
