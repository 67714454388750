import { defineStore } from 'pinia';
import { RouteRecordRaw } from 'vue-router';
import router, { asyncRouterList } from '@/router';
import { store } from '@/store';

function filterPermissionsRouters(routes: Array<RouteRecordRaw>, roles: Array<unknown>) {
  const newAccessedRouters: Array<RouteRecordRaw> = [];
  const parentRouteNodes: Array<RouteRecordRaw> = [];
  const removeRoutes: Array<RouteRecordRaw> = [];
  const removeRoutesMap: Map<string, Array<RouteRecordRaw>> = new Map();
  routes.forEach((route) => {
    if (!!route.meta?.roleCode && roles.indexOf(route.meta?.roleCode) === -1) {
      parentRouteNodes.push(route);
    } else {
      const children: Array<any> = [];
      const removeChildren: Array<any> = [];
      route.children?.forEach((childRouter) => {
        const roleCode = childRouter.meta?.roleCode || childRouter.name;
        if (roles.indexOf(roleCode) !== -1) {
          children.push(childRouter);
        } else {
          removeRoutes.push(childRouter);
          removeChildren.push(childRouter);
        }
      });
      if (children.length > 0) {
        route.children = children;
        newAccessedRouters.push(route);
      }
      if (removeRoutes.length > 0) {
        removeRoutesMap.set(String(route.name), removeChildren);
      }
    }
  });
  return { newAccessedRouters, parentRouteNodes, removeRoutes, removeRoutesMap };
}

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    whiteListRouters: ['/login'],
    routers: [],
    parentRouteNodes: [],
    removeRoutesMap: new Map<string, Array<RouteRecordRaw>>(),
  }),
  actions: {
    async initRoutes(roles: Array<unknown>) {
      let accessedRouters = [];
      let removeRoutes: Array<RouteRecordRaw> = [];
      if (roles.includes('all')) {
        accessedRouters = asyncRouterList;
      } else {
        const res = filterPermissionsRouters(asyncRouterList, roles);
        accessedRouters = res.newAccessedRouters;
        removeRoutes = res.removeRoutes;
        // @ts-ignore
        this.parentRouteNodes = res.parentRouteNodes;
        this.removeRoutesMap = res.removeRoutesMap;
      }
      // @ts-ignore
      this.routers = accessedRouters;
      removeRoutes.forEach((item: RouteRecordRaw) => {
        if (router.hasRoute(String(item.name))) {
          router.removeRoute(String(item.name));
        }
      });
    },
    async restore() {
      const iterator = this.removeRoutesMap.keys();
      let next: Record<string, any>;
      // eslint-disable-next-line no-cond-assign
      while ((next = iterator.next()).value) {
        // @ts-ignore
        this.removeRoutesMap.get(next.value).forEach((item: RouteRecordRaw) => {
          router.addRoute(next.value, item);
        });
      }
    },
  },
});

export function getPermissionStore() {
  return usePermissionStore(store);
}
