import Layout from '@/layouts';

export default [
  {
    path: '/protectionManagement',
    component: Layout,
    redirect: '/protectionManagement/phoneModel',
    name: 'ProtectionManagement',
    meta: { title: '报价管理', icon: 'mobile', single: false, roleCode: '' },
    children: [
      {
        path: 'phoneModel',
        name: 'ProtectionManagementPhoneModel',
        component: () => import('@/pages/protectionManagement/index.vue'),
        meta: { title: '环保二手手机回收报价', roleCode: 'ProtectionManagementPhoneModel', params: { type: 4 } },
      },
      {
        path: 'digitalModel',
        name: 'ProtectionManagementDigitalModel',
        component: () => import('@/pages/protectionManagement/index.vue'),
        meta: { title: '其他数码产品回收报价', roleCode: 'ProtectionManagementDigitalModel', params: { type: 5 } },
      },
      {
        path: 'difficultModel',
        name: 'ProtectionManagementDifficultModel',
        component: () => import('@/pages/protectionManagement/index.vue'),
        meta: { title: '疑难机型报价', roleCode: 'ProtectionManagementDifficultModel', params: { type: 6 } },
      },
    ],
  },
];
